import * as React from 'react'
import { useEffect } from 'react'
import { Link } from 'gatsby'
import AudioPlayer from '../components/audio'
import Switch from '../components/switch'
import {
  pageContainer,
  container,
  homeButton,
  homeIcon,
  navBar,
  navLinks,
  navLinkItem,
  navLinkIcon,
  navLinkIconSvg,
  navLinkText,
  musicSvg,
  aboutSvg,
  appsIcon,
  activeLink,
  audioBox,
  settingsIcon,
  settingsBox,
  pageFooter,
  socIcon
} from './layout.module.css'
import { queueIcon } from '../components/audio.module.css'

const blink = () => {
  const isBrowser = () => typeof document !== "undefined";
  if (isBrowser()) {
    const eyeball1 = document.querySelector('#eyeball1');
    const eyeball2 = document.querySelector('#eyeball2');
    eyeball1.setAttribute("ry","2");
    eyeball2.setAttribute("ry","2");
    setTimeout(()=>{eyeball1.setAttribute("ry","7");},150);
    setTimeout(()=>{eyeball2.setAttribute("ry","7");},150);
  }
};

const toggleSettings = () => {
  const isBrowser = () => typeof document !== "undefined";
  if (isBrowser()) {
    document.querySelector('#queue').style.display="none";
    const settingsMenu = document.querySelector('#settings');
    let menuState = settingsMenu.style.display;
    if (menuState==="none") {settingsMenu.style.display="block";}
    else {settingsMenu.style.display="none";}
  }
}


const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: activeLink }
    : {}
}

const Layout = ({ currentPage, children }) => {
  useEffect(() => {
    const isBrowser = () => typeof document !== "undefined";
    if (isBrowser()) {
      document.addEventListener("click", (e) => {
        // on all pages, hide settingsMenu on click outside menu button
        if (!e.target.closest(`.${settingsIcon}`) && !e.target.closest('#settings')) {
          document.querySelector('#settings').style.display="none";
        }
        if (!e.target.closest(`.${queueIcon}`) && !e.target.closest('#queue')) {
          document.querySelector('#queue').style.display="none";
        }
        // on album pages, hide infoMenu on click outside info button
        if (document.querySelector("#infoMenu")) {
          if (e.target.innerHTML!=="•••") {
            document.querySelector("#infoMenu").style.display = "none";
            document.querySelector("#infoMenu").index = -1;
          }
        }
      });
    }
  }, []);

  const isBrowser = () => typeof document !== "undefined";
  const noUnderline = {textDecoration: "none"};
  let lightSetting = isBrowser() ? localStorage.getItem('preferred-theme') : "dark"; let darkMode;
  if (lightSetting==='dark') {darkMode='on';} else {darkMode='off';}
  let languageSetting = isBrowser() ? localStorage.getItem('language') : "off"; if (languageSetting===null) {languageSetting='off';}
  let autoplaySetting = isBrowser() ? localStorage.getItem('autoplay') : "on"; if (autoplaySetting===null) {autoplaySetting='on';}
  let controlsSetting = isBrowser() ? localStorage.getItem('controls') : "off"; if (controlsSetting===null) {controlsSetting='off';}
  let d = new Date();
  let year = d.getFullYear();
  return (
    <div>
    <div className={pageContainer}>
      <nav className={navBar}>
        <ul className={navLinks}>
          <li className={navLinkItem} title="www.evangedrich.com">
            <Link to="/">
              <div className={homeButton} onMouseEnter={() => blink()} role="presentation">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <circle className={homeIcon} cx="50" cy="50" r="48.5"></circle>
                  <line className={homeIcon} x1="49" y1="18" x2="49" y2="63"></line>
                  <ellipse id="eyeball1" className={homeIcon} cx="28" cy="34" rx="7" ry="7"></ellipse>
                  <ellipse id="eyeball2" className={homeIcon} cx="72" cy="37" rx="7" ry="7"></ellipse>
                  <line className={homeIcon} x1="41" y1="83" x2="59" y2="83"></line>
                </svg>
              </div>
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/music" style={noUnderline} getProps={isPartiallyActive}>
              <div className={navLinkIcon}>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className={musicSvg}>
                  <circle className={navLinkIconSvg} cx="28" cy="60" r="14"></circle>
                  <circle className={navLinkIconSvg} cx="72" cy="78" r="14"></circle>
                  <path className={navLinkIconSvg} d="M42,60 42,7 86,25 86,78"/>
                </svg>
              </div>
              <div className={navLinkText}>Music</div>
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/apps" style={noUnderline} getProps={isPartiallyActive}>
              <div className={navLinkIcon}>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <rect className={`${navLinkIconSvg} ${appsIcon}`} width="30" height="30" x="10" y="5" rx="0" />
                  <rect className={`${navLinkIconSvg} ${appsIcon}`} width="30" height="30" x="10" y="55" rx="0" />
                  <rect className={`${navLinkIconSvg} ${appsIcon}`} width="30" height="30" x="60" y="15" rx="0" />
                  <rect className={`${navLinkIconSvg} ${appsIcon}`} width="30" height="30" x="60" y="65" rx="0" />
                </svg>
              </div>
              <div className={navLinkText}>Apps</div>
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/about" style={noUnderline} getProps={isPartiallyActive}>
              <div className={navLinkIcon}>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className={aboutSvg}>
                  <circle className={navLinkIconSvg} cx="50" cy="35" r="30"></circle>
                  <path className={navLinkIconSvg} d="M35,61 20,93 80,93 65,61"/>
                </svg>
              </div>
              <div className={navLinkText}>About</div>
            </Link>
          </li>
          <li className={audioBox}><AudioPlayer></AudioPlayer></li>
          <li className={settingsIcon} title="Settings" key="settings" onClick={()=>{toggleSettings()}} role="presentation">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle className={navLinkIconSvg} cx="50" cy="50" r="30"></circle>
              <path className={navLinkIconSvg} role="button" d="M20,50 7,50 M50,20 50,7 M50,80 50,93 M80,50 93,50 M28,28 19,19 M72,72 81,81 M28,72 19,81 M72,28 81,19"/>
            </svg>
          </li>
        </ul>
        <div id="settings" className={settingsBox} style={{display:"none"}}>
          <h3>Theme</h3>
          <ul>
            <li>Dark mode<Switch the="lights" to={darkMode}></Switch></li>
            <li>English/Espa&ntilde;ol<Switch the="language" to={languageSetting}></Switch></li>
          </ul>
          <h3>Music</h3>
          <ul>
            <li>Autoplay<Switch the="autoplay" to={autoplaySetting}></Switch></li>
            <li>Shuffle<Switch the="shuffle" to="off"></Switch></li>
            <li>Controls<Switch the="controls" to={controlsSetting}></Switch></li>
          </ul>
        </div>
      </nav>
      <main className={container}>
        {children}
      </main>
      <footer className={pageFooter}>
        <div>
          <a href="http://www.facebook.com/evan.gedrich" aria-label="Facebook" target="_blank" rel="noreferrer" className={socIcon}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><path data-name="layer1" d="M39.312 13.437H47V2h-9.094C26.938 2.469 24.688 8.656 24.5 15.125v5.719H17V32h7.5v30h11.25V32h9.281l1.781-11.156H35.75v-3.469a3.714 3.714 0 0 1 3.562-3.938z" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" strokeLinejoin="round"/></svg></a>
          <a href="http://www.instagram.com/evangedrich" aria-label="Instagram" target="_blank" rel="noreferrer" className={socIcon}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><path data-name="layer2" d="M44.122 2H19.87A17.875 17.875 0 0 0 2 19.835v24.2a17.875 17.875 0 0 0 17.87 17.834h24.252A17.875 17.875 0 0 0 62 44.034v-24.2A17.875 17.875 0 0 0 44.122 2zm0 0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" strokeLinejoin="round"/><path data-name="layer1" d="M32 16.45a15.484 15.484 0 1 0 15.514 15.484A15.519 15.519 0 0 0 32 16.45zm0 24.95a9.461 9.461 0 1 1 9.482-9.461A9.472 9.472 0 0 1 32 41.4zm19.263-24.834a3.719 3.719 0 1 1-3.719-3.711 3.714 3.714 0 0 1 3.719 3.711zm0 0" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" strokeLinejoin="round"/></svg></a>
          <a href="http://www.linkedin.com/in/evan-gedrich" aria-label="LinkedIn" target="_blank" rel="noreferrer" className={socIcon}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 64 64"><path data-name="layer1" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" d="M3.078 22.331h12.188v36.844H3.078z" strokeLinejoin="round"/><path data-name="layer2" d="M46.719 21.112c-5.344 0-8.531 1.969-11.906 6.281v-5.062H22.625v36.844h12.281V39.206c0-4.219 2.156-8.344 7.031-8.344s7.781 4.125 7.781 8.25v20.063H62V38.269c0-14.532-9.844-17.157-15.281-17.157z" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" strokeLinejoin="round"/><path data-name="layer1" d="M9.219 4.425C5.188 4.425 2 7.331 2 10.894s3.188 6.469 7.219 6.469 7.219-2.906 7.219-6.469-3.188-6.469-7.219-6.469z" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" strokeLinejoin="round"/></svg></a>
          <a href="https://open.spotify.com/user/sunshinesuperman7" aria-label="Spotify" target="_blank" rel="noreferrer" className={socIcon}><svg width="24" height="24" viewBox="0 0 64 64"><path data-name="layer2" d="M32 2a30 30 0 1 0 30 30A29.968 29.968 0 0 0 32 2z" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3" strokeLinejoin="round" /><path data-name="layer1" d="M43.531 46.25a2.488 2.488 0 0 1-1.313-.469 30.038 30.038 0 0 0-15.562-4.031 45.077 45.077 0 0 0-9.469 1.031 10.354 10.354 0 0 1-1.5.281 1.879 1.879 0 0 1-1.875-1.875 1.978 1.978 0 0 1 1.594-2.063 50.53 50.53 0 0 1 11.25-1.313 33.55 33.55 0 0 1 17.437 4.5 2 2 0 0 1 1.219 1.969 1.73 1.73 0 0 1-1.781 1.97zm3.281-7.969a2.657 2.657 0 0 1-1.5-.562 38.834 38.834 0 0 0-19.031-4.781 40.6 40.6 0 0 0-9.75 1.219 4.413 4.413 0 0 1-1.5.375 2.321 2.321 0 0 1-2.344-2.344 2.44 2.44 0 0 1 1.875-2.531 41.027 41.027 0 0 1 11.813-1.687A41.912 41.912 0 0 1 47.75 33.5a2.421 2.421 0 0 1 1.406 2.344 2.344 2.344 0 0 1-2.344 2.437zm3.75-9.187a2.833 2.833 0 0 1-1.5-.469c-5.531-3.281-13.969-5.062-22.031-5.062a46.712 46.712 0 0 0-12 1.406 8.442 8.442 0 0 1-1.5.281 2.842 2.842 0 0 1-2.812-2.906 3.012 3.012 0 0 1 2.062-2.906 52.412 52.412 0 0 1 14.25-1.875c8.812 0 18.094 1.781 24.844 5.813a2.836 2.836 0 0 1 1.5 2.719 2.867 2.867 0 0 1-2.813 2.999z" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" strokeLinejoin="round" /></svg></a>
          <a href="https://evangedrich.bandcamp.com/" aria-label="Bandcamp" target="_blank" rel="noreferrer" className={socIcon}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-1 -1 30 30"><path d="M16.719 18.406l4.781-8.813h-10.219l-4.781 8.813h10.219zM28 14c0 7.734-6.266 14-14 14s-14-6.266-14-14 6.266-14 14-14 14 6.266 14 14z" strokeWidth="1.5"/></svg></a>
        </div>
        <div>Copyright &copy; {year} Evan Gedrich. All rights reserved.</div>
      </footer>
    </div>
    </div>
  )
}

export default Layout
