import { songTitle, nowPlaying } from './albums.module.css'

export const updateProgress = (audio) => {
  audio.play();
  const formatString = (time) => {
    let round;
    if ((Math.round(time))%60 < 10) {
      round = Math.floor(Math.round(time)/60) + ":0" + (Math.round(time))%60;
    } else {
      round = Math.floor(Math.round(time)/60) + ":" + (Math.round(time))%60;
    } return round
  }
  audio.ontimeupdate = () => {
    const isBrowser = () => typeof document !== "undefined";
    if (isBrowser()) {
      document.querySelector("#currentElapsed").innerHTML = formatString(audio.currentTime);
      document.querySelector("#currentDuration").innerHTML = formatString(audio.duration);
      document.querySelector("#progressBarElapsed").style.width = (audio.currentTime / audio.duration)*100 + '%';
      if (audio.currentTime===audio.duration) {
        audio.currentTime = 0;
        document.querySelector("#currentElapsed").innerHTML = "0:00";
        document.querySelector("#progressBarElapsed").style.width = "0%";
        let activeTr = document.getElementsByClassName(nowPlaying);
        for (let i=0; i<activeTr.length; i++) {activeTr[i].classList.remove(nowPlaying);}
        let canAutoplay = isBrowser() ? localStorage.getItem('autoplay') : "on";
        if (canAutoplay==="on") {
          // add next <audio> to queue, run it in updateProgress
          let upNext = document.querySelector("#queue").upNext;
          if (upNext.length===0) {
            document.querySelector("#playDiv").firstChild.firstChild.setAttribute('d','M32,10 32,90 98,50 z');
            document.querySelector("#playDiv").title="Play";
          } else {
            let songEls = document.getElementsByClassName(songTitle);
            for (let i=0; i<songEls.length; i++) {
              if (songEls[i].innerText===upNext[0].title) {
                songEls[i].parentNode.parentNode.classList.add(nowPlaying);
                songEls[i].parentNode.parentNode.querySelector("div").title = "Pause "+songEls[i].parentNode.parentNode.querySelector("div").title.substring(5);
              }
            }
            let nextAudio = new Audio(upNext[0].path.publicURL);
            document.querySelector("#queue").querySelector("audio").remove();
            document.querySelector("#queue").appendChild(nextAudio);
            document.querySelector("#currentTitle").innerHTML = upNext[0].title;
            document.querySelector("#currentArtist").innerHTML = upNext[0].artist;
            document.querySelector("#queue").previous.push(upNext[0]);
            document.querySelector("#queue").upNext.shift();
            updateProgress(document.querySelector("#queue").querySelector("audio"));
          }
          updateQueue(upNext);
        } else if (canAutoplay==="off") {
          document.querySelector("#playDiv").firstChild.firstChild.setAttribute('d','M32,10 32,90 98,50 z');
        }
      }
    }
  }
  const updateQueue = (upNext) => {
    const isBrowser = () => typeof document !== "undefined";
    if (isBrowser()) {
      // update the names in the queue box
      let ul = document.querySelector("#queue ul");
      while (ul.firstChild) { ul.removeChild(ul.firstChild); }
      if (upNext.length>0) {
        for (let i=0; i<upNext.length; i++) {
          let li = document.createElement("li");
          li.appendChild(document.createTextNode(upNext[i].title));
          ul.appendChild(li);
        }
      } else {
        let li = document.createElement("li");
        li.appendChild(document.createTextNode("No songs in queue."));
        ul.appendChild(li);
      }
    }
  }
}

export const setSong = (song) => {
  const isBrowser = () => typeof document !== "undefined";
  if (isBrowser()) {
    let songEls = document.getElementsByClassName(songTitle);
    for (let i=0; i<songEls.length; i++) {
      if (songEls[i].innerText===song.title) {
        songEls[i].parentNode.parentNode.classList.add(nowPlaying);
        songEls[i].parentNode.parentNode.querySelector("div").title = "Pause "+songEls[i].parentNode.parentNode.querySelector("div").title.substring(5);
      } else {songEls[i].parentNode.parentNode.classList.remove(nowPlaying);}
    }
    if (document.querySelector("#queue").querySelector("audio")!==null)
    {document.querySelector("#queue").querySelector("audio").remove();}
    let nextSong = new Audio(song.path.publicURL);
    document.querySelector("#queue").appendChild(nextSong);
    document.querySelector("#currentTitle").innerHTML = song.title;
    document.querySelector("#currentArtist").innerHTML = song.artist;
    updateProgress(document.querySelector("#queue").querySelector("audio"));
  }
}
