// extracted by mini-css-extract-plugin
export var container = "audio-module--container--8s+MA";
export var currentSong = "audio-module--current-song--WQue0";
export var currentSongTitle = "audio-module--current-song-title--IRvba";
export var currentSongArtist = "audio-module--current-song-artist--zRfET";
export var playButton = "audio-module--play-button--IwHEf";
export var nextButton = "audio-module--next-button--f4nnh";
export var prevButton = "audio-module--prev-button--iflfH";
export var queueIcon = "audio-module--queue-icon--gYHGq";
export var progressContainer = "audio-module--progress-container--2K3a6";
export var progressBar = "audio-module--progress-bar--KGkoC";
export var progressBarElapsed = "audio-module--progress-bar-elapsed--C7s6A";
export var progressTimeElapsed = "audio-module--progress-time-elapsed--R6Hja";
export var progressTimeTotal = "audio-module--progress-time-total--DfFCC";
export var queueBox = "audio-module--queue-box--00a7f";