exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-decide-js": () => import("./../../../src/pages/apps/decide.js" /* webpackChunkName: "component---src-pages-apps-decide-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-apps-lights-js": () => import("./../../../src/pages/apps/lights.js" /* webpackChunkName: "component---src-pages-apps-lights-js" */),
  "component---src-pages-apps-newsletter-js": () => import("./../../../src/pages/apps/newsletter.js" /* webpackChunkName: "component---src-pages-apps-newsletter-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index-copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-music-index-js": () => import("./../../../src/pages/music/index.js" /* webpackChunkName: "component---src-pages-music-index-js" */),
  "component---src-pages-music-mdx-slug-js": () => import("./../../../src/pages/music/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-music-mdx-slug-js" */)
}

