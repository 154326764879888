import * as React from 'react'

const container = {
  cursor: "pointer",
  marginTop: 3
}
const switchBox = {
  position: "relative",
  width: 28,
  height: 16,
  boxSizing: "border-box",
  border: "1px solid var(--color-front)",
  borderRadius: "1em",
  pointerEvents: "none"
}
const switchOff = {
  position: "absolute",
  width: 10,
  height: 10,
  boxSizing: "border-box",
  border: "1px solid var(--color-front)",
  borderRadius: "50%",
  margin: 2,
  left: 0
}
const switchOn = {
  position: "absolute",
  width: 10,
  height: 10,
  boxSizing: "border-box",
  border: "1px solid var(--color-front)",
  borderRadius: "50%",
  margin: 2,
  backgroundColor: "var(--color-front)",
  right: 0
}

const setTheme = () => {
  const isBrowser = () => typeof document !== "undefined";
  let setting = isBrowser() ? localStorage.getItem('preferred-theme') : "dark";
  if (setting!=='light' && isBrowser()) {localStorage.setItem('preferred-theme','dark');}
  if (isBrowser()) {
    const root = document.documentElement;
    if (setting==="light") {
      root.style.setProperty('--color-back','#EDE9E3');
      root.style.setProperty('--color-front','#282828');
      root.style.setProperty('--color-border','#CCCCCC');
      root.style.setProperty('--color-highlight','darkslateblue');
    } else {
      root.style.setProperty('--color-back','#181818');
      root.style.setProperty('--color-front','white');
      root.style.setProperty('--color-border','#333333');
      root.style.setProperty('--color-highlight','gold');
    }
  }
}

const setLanguage = () => {
  const isBrowser = () => typeof document !== "undefined";
  let setting = isBrowser() ? localStorage.getItem('language') : "off";
  if (setting===null && isBrowser()) {localStorage.setItem('language','off');}
}

const setAutoplay = () => {
  const isBrowser = () => typeof document !== "undefined";
  let setting = isBrowser() ? localStorage.getItem('autoplay') : "on";
  if (setting===null && isBrowser()) {localStorage.setItem('autoplay','on');}
}

const setControls = () => {
  const isBrowser = () => typeof document !== "undefined";
  let setting = isBrowser() ? localStorage.getItem('controls') : "off";
  if (setting===null && isBrowser()) {localStorage.setItem('controls','off');}
  // force controls on if isMobile
  const isMobile = (typeof window !== "undefined") && window.matchMedia("(max-width: 700px)").matches;
  if (isMobile) {setting = "on"; localStorage.setItem('controls','on');}
  if (isBrowser()) {
    let progress = document.querySelector("#progress");
    let queue = document.querySelector("#queueIcon");
    let prev = document.querySelector("#prevDiv");
    let next = document.querySelector("#nextDiv");
    if (setting==='on') {
      if (progress!==null) {progress.style.display='block';}
      if (!isMobile) {if (queue!==null) {queue.style.display='block';}} else {if (queue!==null) {queue.style.display='none';}}
      if (prev!==null) {prev.style.display='block';}
      if (next!==null) {next.style.display='block';}
    } else {
      if (progress!==null) {progress.style.display='none';}
      if (queue!==null) {queue.style.display='none';}
      if (prev!==null) {prev.style.display='none';}
      if (next!==null) {next.style.display='none';}
    }
  }
}

const toggle = (e,the) => {
  const isBrowser = () => typeof document !== "undefined";
  const switchContainer = e.target;
  const lever = e.target.querySelector('#lever');
  let isRight = !lever.style.right;
  if (isRight) { // was on the left at click
    lever.style.backgroundColor = "var(--color-front)";
    lever.style.removeProperty('left');
    lever.style.right = 0;
    switchContainer.className = "on"; if (isBrowser()) {
    if (the==="lights") {localStorage.setItem('preferred-theme','dark'); setTheme();}
    if (the==="language") {localStorage.setItem('language','on'); setLanguage();}
    if (the==="autoplay") {localStorage.setItem('autoplay','on'); setAutoplay();}
    if (the==="controls") {localStorage.setItem('controls','on'); setControls();} }
  } else { // was on the right at click
    lever.style.removeProperty('background-color');
    lever.style.removeProperty('right');
    lever.style.left = 0;
    switchContainer.className = "off"; if (isBrowser()) {
    if (the==="lights") {localStorage.setItem('preferred-theme','light'); setTheme();}
    if (the==="language") {localStorage.setItem('language','off'); setLanguage();}
    if (the==="autoplay") {localStorage.setItem('autoplay','off'); setAutoplay();}
    if (the==="controls") {localStorage.setItem('controls','off'); setControls();} }
  }
}

const Switch = ({ the, to }) => {
  if (the==='lights') {setTheme();}
  if (the==='language') {setLanguage();}
  if (the==='autoplay') {setAutoplay();}
  if (the==='controls') {setControls();}
  let switchDot;
  if (to==="on") {switchDot=switchOn;}
  else {switchDot=switchOff;}
  return (
    <div style={container} onClick={((e)=>toggle(e,the))} className={to} onKeyDown={(e)=>{if(e.keyCode===32){toggle()}}} role="presentation">
      <div style={switchBox}>
        <div style={switchDot} id="lever">
        </div>
      </div>
    </div>
  )
}

export default Switch
