import * as React from 'react'
//import { useStaticQuery, graphql } from 'gatsby'
import { setSong } from './progress'
import { container, playButton, prevButton, nextButton, progressContainer,
  currentSong, currentSongTitle, currentSongArtist,
  progressBar, progressTimeElapsed, progressBarElapsed,
  progressTimeTotal, queueIcon, queueBox
} from './audio.module.css'
import { nowPlaying, wasPaused } from './albums.module.css'

const AudioPlayer = () => {
  /*const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          exports {
            songs {
              path {
                publicURL
              }
              title
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
  `)*/

  const play = (e) => {
    let outside = e.currentTarget.title;
    let button = e.currentTarget.firstChild.firstChild;
    if (outside==="Play") {
      e.currentTarget.title = "Pause";
      button.setAttribute('d','M17,10 17,90 37,90 37,10 z M63,10 63,90 83,90 83,10 z');
      if (document.querySelector("#queue").querySelector("audio")!==null)
      {document.querySelector("#queue").querySelector("audio").play();}
      let songEls = document.getElementsByClassName(nowPlaying);
      if (songEls.length>0) {songEls[0].firstChild.classList.remove(wasPaused);
      songEls[0].querySelector("div").title = "Pause "+songEls[0].querySelector("div").title.substring(5);}
    } if (outside==="Pause") {
      e.currentTarget.title = "Play";
      button.setAttribute('d','M17,10 17,90 83,50 z');
      if (document.querySelector("#queue").querySelector("audio")!==null)
      {document.querySelector("#queue").querySelector("audio").pause();}
      let songEls = document.getElementsByClassName(nowPlaying);
      if (songEls.length>0) {songEls[0].firstChild.classList.add(wasPaused);
      songEls[0].querySelector("div").title = "Play "+songEls[0].querySelector("div").title.substring(6);}
    }
  }
  const prev = (e) => {
    if (document.querySelector("#queue").querySelector("audio")!==null) {
      let audio = document.querySelector("#queue").querySelector("audio");
      let prev = document.querySelector("#queue").previous;
      if (audio.currentTime>3 || prev.length<=1) {audio.currentTime=0}
      else { // go to previous song
        setSong(prev[prev.length-2]);
        document.querySelector("#queue").upNext.unshift(document.querySelector("#queue").previous.pop());
        updateQueue(document.querySelector("#queue").upNext);
      }
    }
  }
  const next = (e) => {
    let upNext = document.querySelector("#queue").upNext;
    if (upNext.length===0) {
      if (document.querySelector("#queue").querySelector("audio")!==null) {
        setSong(document.querySelector("#queue").previous[0]);
        document.querySelector("#queue").upNext = document.querySelector("#queue").previous.slice(1);
        document.querySelector("#queue").previous = [document.querySelector("#queue").previous[0]];
      }
    } else {
      setSong(upNext[0]);
      document.querySelector("#queue").previous.push(document.querySelector("#queue").upNext.shift());
    }
    updateQueue(document.querySelector("#queue").upNext);
  }

  const skip = (e) => {
    let x = e.clientX - e.target.getBoundingClientRect().left;
    let percentage = x / e.currentTarget.clientWidth;
    let barElapsed = e.currentTarget.firstChild;
    barElapsed.style.width = (percentage*100)+"%";
    if (document.querySelector("#queue").querySelector("audio")!==null) {
      let audio = document.querySelector("#queue").querySelector("audio");
      audio.currentTime = audio.duration*percentage;
    }
  }

  const scrollText = (e) => {
    let maxWidth = e.target.parentElement.clientWidth;
    let excessWidth = e.target.scrollWidth - maxWidth;
    if (excessWidth>0) {e.target.style.marginLeft = (0-excessWidth)+"px";
    e.target.style.transition = `margin-left ${(excessWidth/maxWidth)*1200}ms linear`;}
  }
  const resetText = (e) => {
    if (e.target.style.marginLeft!=="0px") {e.target.style.marginLeft = "0px";}
  }

  const toggleQueue = (e) => {
    e.stopPropagation();
    document.querySelector('#settings').style.display = 'none';
    let queueElement = document.querySelector('#queue');
    if (queueElement.style.display==='none') {queueElement.style.display = 'block';}
    else if (queueElement.style.display==='block') {queueElement.style.display = 'none';}
  }

  const isMobile = (typeof window !== "undefined") && window.matchMedia("(max-width: 700px)").matches;
  let visibility = 'none';
  const isWindow = () => typeof window !== "undefined";
  let controlsSetting = isWindow() ? localStorage.getItem('controls') : "off";
  if (controlsSetting==="on") {visibility = 'block';}

  const updateQueue = (upNext) => {
    // update the names in the queue box
    let ul = document.querySelector("#queue ul");
    while (ul.firstChild) { ul.removeChild(ul.firstChild); }
    if (upNext.length>0) {
      for (let i=0; i<upNext.length; i++) {
        let li = document.createElement("li");
        li.appendChild(document.createTextNode(upNext[i].title));
        ul.appendChild(li);
      }
    } else {
      let li = document.createElement("li");
      li.appendChild(document.createTextNode("...queue empty"));
      ul.appendChild(li);
    }
  }

  return (
    <div className={container} id="musicPlayer">
      <div className={currentSong}>
        <div className={currentSongTitle} onMouseEnter={scrollText} onMouseLeave={resetText} role="presentation" id="currentTitle"></div>
        <div className={currentSongArtist} onMouseEnter={scrollText} onMouseLeave={resetText} role="presentation" id="currentArtist"></div>
      </div>
      <div id="prevDiv" className={prevButton} title="Previous" onClick={prev} role="presentation" style={{display:visibility}}>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <path d="M83,10 83,90 17,50z M15,10 15,90" stroke="var(--color-front)" strokeWidth="7" fill="transparent"></path>
        </svg>
      </div>
      <div id="playDiv" className={playButton} title="Play" onClick={play} role="presentation">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <path d="M17,10 17,90 83,50 z"></path>
        </svg>
      </div>
      <div id="nextDiv" className={nextButton} title="Next" onClick={next} role="presentation" style={{display:visibility}}>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <path d="M17,10 17,90 83,50z M85,10 85,90" stroke="var(--color-front)" strokeWidth="7" fill="transparent"></path>
        </svg>
      </div>
      <div className={progressContainer} id="progress" style={{display:visibility}}>
        <div className={progressBar} onClick={skip} role="presentation">
          <div className={progressBarElapsed} id="progressBarElapsed"></div>
        </div>
        <div className={progressTimeElapsed} id="currentElapsed">0:00</div>
        <div className={progressTimeTotal} id="currentDuration">0:00</div>
      </div>
      <div className={queueIcon} id="queueIcon" title="Queue" onClick={toggleQueue} style={{display:isMobile?'none':visibility}} role="presentation">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <path d="M10,33 35,20 10,7z M50,20 93,20 M7,50 93,50 M7,80 93,80" stroke="var(--color-front)" strokeWidth="4" fill="transparent"></path>
        </svg>
      </div>
      <div id="queue" className={queueBox} style={{display:'none'}}>
        <h3 id="upNext">Up Next</h3>
        <ul>
          <li>No songs in queue.</li>
        </ul>
      </div>
    </div>
  )
}

export default AudioPlayer
