// extracted by mini-css-extract-plugin
export var container = "albums-module--container--Itr7b";
export var infoMenu = "albums-module--info-menu--4dssN";
export var activeItem = "albums-module--active-item--FrdD7";
export var mobileMenu = "albums-module--mobile-menu--Ke0su";
export var albumCover = "albums-module--album-cover--aAgKq";
export var description = "albums-module--description--w9ty5";
export var mediaBox = "albums-module--media-box--V6+jA";
export var mediaIcon = "albums-module--media-icon--UgsTT";
export var songTitle = "albums-module--song-title--wdIlM";
export var songArtist = "albums-module--song-artist--jv3nk";
export var discFont = "albums-module--disc-font--eiW4U";
export var smallPlay = "albums-module--small-play--xMqyo";
export var nowPlaying = "albums-module--now-playing--d6ZTs";
export var wasPaused = "albums-module--was-paused--AFOep";
export var pressLink = "albums-module--press-link--gS+ir";