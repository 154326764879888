// extracted by mini-css-extract-plugin
export var pageContainer = "layout-module--page-container--zZQ8L";
export var container = "layout-module--container--eLBMS";
export var homeButton = "layout-module--home-button--L2aA5";
export var homeIcon = "layout-module--home-icon--nwnEj";
export var navBar = "layout-module--nav-bar--rVFta";
export var navLinks = "layout-module--nav-links--EROwB";
export var navLinkItem = "layout-module--nav-link-item--pfCo2";
export var navLinkIcon = "layout-module--nav-link-icon--WBlHo";
export var navLinkIconSvg = "layout-module--nav-link-icon-svg--hzeoL";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var appsIcon = "layout-module--apps-icon--fED9r";
export var musicSvg = "layout-module--music-svg--CsDAd";
export var aboutSvg = "layout-module--about-svg--huENR";
export var activeLink = "layout-module--active-link--OEAb-";
export var audioBox = "layout-module--audio-box--ZUR1y";
export var settingsIcon = "layout-module--settings-icon--j7PHJ";
export var settingsBox = "layout-module--settings-box--eQRdK";
export var heading = "layout-module--heading--8VjLO";
export var centerHeading = "layout-module--center-heading--XvF+q";
export var description = "layout-module--description--OJdEI";
export var homeTitle = "layout-module--home-title--H7Rwt";
export var externalLink = "layout-module--external-link--ANroe";
export var pageFooter = "layout-module--page-footer--5Al7J";
export var socIcon = "layout-module--soc-icon--3jHra";